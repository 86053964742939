<template>
  <div id="items_in_cart" class="footer__text">
    <span v-if="without_payment">Мой заказ</span>
    <span v-else>Избранное</span>

    <div class="footer__label" v-if="totalCount > 0">+ {{ totalCount }}</div>
  </div>
</template>

<script>
  export default {
    data: function () {
      return {
        without_payment: false
      }
    },
    mounted() {
      this.without_payment = menu.restaurant.without_payment;
    },
    computed: {
      totalCount() {
        return this.$store.getters.getItemsInCart;
      }
    }
  }
</script>

<style scoped>
</style>
