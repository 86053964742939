// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.

import {createConsumer} from "@rails/actioncable"

window.App || (window.App = {
  cart: null,
  channels: {
    orders: null,
    ordersStatus: {}
  },
  services: {},
  cable: null
});
App.cable = createConsumer();

export default App.cable;
