<template>
  <div id="waiter_ring" class="index__ring">
    <div class="ring" :class="status == 1 ? '' : 'ring--text'" @click="waiterRing()">
      <div class="ring__text ring__text--1" :class="status == 0 ? 'ring__text--show' : ''">
        <!-- Вы всегда можете позвать<br> официанта, нажав на<br> колокольчик -->
        Позвать официанта
      </div>

      <div class="ring__text ring__text--2" :class="status == 2 ? 'ring__text--show' : ''">
        Уже иду
      </div>
    </div>
  </div>
</template>

<script>
  let debounce = require('lodash.debounce');

  export default {
    data: function () {
      return {
        status: 0
      }
    },
    mounted() {
      let self = this;
      setTimeout(function () {
        if (self.status < 1) {
          self.status = 1;
        }
      }, 3000);
    },
    methods: {
      waiterRing: debounce(function () {
        let self = this;
        self.status = 2;
        setTimeout(function () {
          if (self.status > 1) {
            self.status = 1;
          }
        }, 3000);

        App.channels.orders.send({
          action: "waiter_ring",
          table: this.$store.getters.getTable
        });
      }, 500)
    }
  }
</script>

<style scoped>
</style>
