import Vue from 'vue/dist/vue.esm';

let cart = window.sessionStorage.getItem('cart');
let cartCount = window.sessionStorage.getItem('cartCount');
let totalPrice = window.sessionStorage.getItem('totalPrice');
let table = window.sessionStorage.getItem('table');
let myOrders = window.sessionStorage.getItem('myOrders');

let store = {
  state: {
    cart: cart ? JSON.parse(cart) : [],
    cartCount: cartCount ? parseInt(cartCount) : 0,
    totalPrice: totalPrice ? parseFloat(totalPrice) : 0,
    table: table ? table : '',
    myOrders: myOrders ? JSON.parse(myOrders) : []
  },
  
  mutations: {
    addToCart(state, item) {
      let found = state.cart.find(product => product.id == item.id);
      
      let price = parseFloat(item.price, 10);
      
      if (found) {
        found.count++;
        found.totalPrice = found.count * price;
        
        menu.dishes[item.id].count_in_basket = found.count;
      } else {
        state.cart.push(item);
        
        menu.dishes[item.id].count_in_basket = 1;
        
        Vue.set(item, 'count', 1);
        Vue.set(item, 'totalPrice', price);
      }
      
      state.cartCount++;
      
      this.commit('saveCart');
    },
    removeFromCart(state, item) {
      let found = state.cart.find(product => product.id == item.id);
      
      if (found && found.count > 0) {
        found.count--;
        found.totalPrice = found.count * found.price;
        state.cartCount--;
        
        if (typeof menu.dishes[item.id] !== 'undefined') {
          menu.dishes[item.id].count_in_basket = found.count;
        }
      }
      
      // if (index > -1) {
      // let product = state.cart[index];
      // state.cartCount -= product.count;
      // state.cart.splice(index, 1);
      // }
      
      this.commit('saveCart');
    },
    saveCart(state) {
      // суммарная стоимость блюд
      let total = 0, cart = state.cart, o = (typeof menu.bl_prices_ordered !== 'undefined'),
        by_parent = {}, sets = {}, masks = {}, masks_order = [];
      
      // собираем маски поиска набора бизнес-ланча
      for (let cat of menu.bl_prices_ordered) {
        let joined = parseInt(cat.categories.sort().reverse().join(''), 10);
        masks[joined] = cat.price;
        
        if (masks_order.indexOf(joined) < 0) {
          masks_order.push(joined);
        }
      }
      masks_order = masks_order.sort().reverse();
      
      // перебираем позиции в корзине, выделяя блюда основного меню и блюда бизнес-ланча
      for (let item of cart) {
        let dish = menu.dishes[item.id];
        if (typeof dish !== 'undefined') {
          let category = menu.categories[dish.parent], grand = category.parent;
          if (grand === 1 && o) {
            for (let i = 0; i < item.count; i++) {
              if (typeof by_parent[dish.parent] == 'undefined') {
                by_parent[dish.parent] = 0;
              }
              by_parent[dish.parent] += 1;
            }
          } else {
            total += item.totalPrice;
          }
        }
      }
      
      // группируем выбранные блюда бизнес-ланча по родительской категории
      for (let p in by_parent) {
        for (let i = 0; i < by_parent[p]; i++) {
          if (typeof sets[i] == 'undefined') {
            sets[i] = [];
          }
          sets[i].push(p);
        }
      }
      
      // выбираем подходящую стоимость для конкретного набора блюд
      for (let i in sets) {
        let search = sets[i].sort().reverse().join('.*'), price = 0;
        // console.log(masks_order, search);
        for (let mask of masks_order) {
          if (('' + mask).match(search) !== null) {
            price = masks[mask];
          }
        }
        total += price;
      }
      
      // выбросим пустые
      let non_empty = [];
      for (let item of state.cart) {
        if (item.count > 0) {
          non_empty.push(item);
        }
      }
      state.cart = non_empty;
      
      // возвращаем итоговую стоимость блюд
      state.totalPrice = total;

      // сохраним состояние
      window.sessionStorage.setItem('cart', JSON.stringify(state.cart));
      window.sessionStorage.setItem('cartCount', state.cartCount);
      window.sessionStorage.setItem('totalPrice', total);
    },
    setTable(state, table) {
      state.table = table;
      window.sessionStorage.setItem('table', table);
    },
    clearCart(state) {
      let self = this;
      state.cart.forEach(function(item){
        for (let i = item.count; i > 0; i--) {
          self.commit('removeFromCart', item);
        }
      });
    },
    addMyOrder(state, order_code) {
      let found = state.myOrders.find(code => code == order_code);
      
      if (!found) {
        state.myOrders.push(order_code);
      }
      window.sessionStorage.setItem('myOrders', JSON.stringify(state.myOrders));
    }
  },
  
  getters: {
    getCart(state) {
      return state.cart;
    },
    getItemsInCart(state) {
      return state.cartCount;
    },
    getTotalPrice(state) {
      return state.totalPrice.toFixed(2);
    },
    getTable(state) {
      return state.table;
    },
    getMyOrders(state) {
      return state.myOrders;
    }
  }
};

export default store;
