import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue/dist/vue.esm';
import consumer from "../channels/consumer";
import Vuex from 'vuex';
import dishes_menu from '../dishes_menu.vue';
import store from './store.js';

Vue.use(TurbolinksAdapter);
Vue.use(Vuex);

document.addEventListener('turbolinks:load', () => {
  const cart = new Vue({
    el: '#main_content',
    store: new Vuex.Store(store),
    
    data: () => {
      return {}
    },
    components: {dishes_menu}
  });
  
  App.cart = cart;
  
  // console.log('//@ Тут события срабатывают каждый раз при переходе по ссылкам…');
});
