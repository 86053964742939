<template>
  <div id="my_order" class="order">
    <div class="cards">
      <div class="cards__part">
        <div v-for="group of groupped_dishes">
          <div class="cards__header" v-if="group.bl">
            {{ menu.categories[1].name }}
          </div>
          <div class="cards__header" v-else>
            {{ menu.categories[menu.dishes[Object.keys(group.dishes)[0]].parent].name }}
          </div>

          <div v-for="(count, dish_id) in group.dishes">
            <dish_card v-bind:dish_id="dish_id" v-bind:key="dish_id" v-bind:count="count"></dish_card>
          </div>

          <div v-for="bl of group.bl">
            <div class="order__total order__total--pt">
              <strong class="order__list">{{ menu.bl_prices[bl.key].name }}</strong>
            </div>

            <div v-for="dish_id in bl.dishes">
              <dish_card v-bind:dish_id="dish_id" v-bind:key="dish_id" v-bind:count="1"></dish_card>
            </div>

            <div class="card__price">
              <div v-if="menu.bl_prices[bl.key].price">
                <div class="order__total order__total--mb">
                  ₽ {{ menu.bl_prices[bl.key].price }}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="order__button" v-if="show_order_button">
      <a href="#" :class="[is_sent > 0 || totalPrice === '0.00' ? 'button button--disable' : 'button']"
         @click="is_sent < 1 ? is_sent = 1 : false"
         @click.prevent="preSendOrder">{{order_button_text}}</a>
    </div>
    <div class="order__button" v-else>
      <div class="order__total">
        Позовите официанта и сообщите ему
        <br>Ваш заказ
      </div>
    </div>

    <div class="cards js--my_orders" v-if="show_my_orders">
      <div class="cards__header cards__header--opacity">
        Уже заказано
      </div>
      <div v-for="(order,order_code) in my_orders" v-html="order" v-bind:key="order_code"></div>
    </div>
    <div class="cards js--my_orders" v-if="show_pending_orders">
      <div class="cards__header cards__header--opacity">
        Оплата не прошла
      </div>
      <div v-for="(order,order_code) in pending_orders" v-html="order" v-bind:key="order_code"></div>
    </div>
    <div class="order__total" v-if="totalPrice !== '0.00'">
      Итог: ₽ {{ totalPrice }}
    </div>
  </div>
</template>

<script>
import dish_card from './dish_card';

export default {
  components: {dish_card},
  data: function () {
    return {
      is_sent: 0,
      show_my_orders: 0,
      show_pending_orders: 0,
      my_orders: {},
      pending_orders: {},
      menu: menu,

      without_payment: false,
      show_order_button: false, // скроем кнопку заказа
      order_button_text: 'Заказать и оплатить'
    }
  },
  computed: {
    totalPrice() {
      return this.$store.getters.getTotalPrice;
    },
    groupped_dishes() {
      // группируем блюда
      let total = 0, cart = this.$store.getters.getCart, o = (typeof menu.bl_prices_ordered !== 'undefined'),
        by_parent = {}, sets = {}, masks = {}, masks_order = [], masks_keys = {},
        groupped_dishes = {}, dishes_in_parents = {};

      // собираем маски поиска набора бизнес-ланча
      for (let cat of menu.bl_prices_ordered) {
        let joined = parseInt(cat.categories.sort().reverse().join(''), 10);
        masks[joined] = cat.price;

        if (masks_order.indexOf(joined) < 0) {
          masks_order.push(joined);
        }

        if (typeof masks_keys[joined] === 'undefined') {
          masks_keys[joined] = cat.id;
        }

      }
      masks_order = masks_order.sort().reverse();

      // перебираем позиции в корзине, выделяя блюда основного меню и блюда бизнес-ланча
      for (let item of cart) {
        let dish = menu.dishes[item.id];
        if (typeof dish !== 'undefined') {
          let category = menu.categories[dish.parent], grand = category.parent;
          if (grand === 1 && o) {
            for (let i = 0; i < item.count; i++) {
              if (typeof by_parent[dish.parent] == 'undefined') {
                by_parent[dish.parent] = 0;
              }
              by_parent[dish.parent] += 1;

              if (typeof dishes_in_parents[dish.parent] == 'undefined') {
                dishes_in_parents[dish.parent] = [];
              }
              dishes_in_parents[dish.parent].push(item.id);
            }
          } else {
            if (typeof groupped_dishes[category.id] === 'undefined') {
              groupped_dishes[category.id] = {dishes: {}};
            }
            groupped_dishes[category.id].dishes[item.id] = item.count;
          }
        }
      }

      // группируем выбранные блюда бизнес-ланча по родительской категории
      for (let p in by_parent) {
        for (let i = 0; i < by_parent[p]; i++) {
          if (typeof sets[i] == 'undefined') {
            sets[i] = [];
          }
          sets[i].push(p);
        }
      }

      // выбираем подходящую стоимость для конкретного набора блюд
      let iterators = {};
      for (let i in sets) {
        let search = sets[i].sort().reverse().join('.*'), price = 0, mask_key = 0;
        // console.log(masks_order, search);
        for (let mask of masks_order) {
          if (('' + mask).match(search) !== null) {
            price = masks[mask];
            mask_key = masks_keys[mask];
          }
        }

        if (typeof groupped_dishes[1] === 'undefined') {
          groupped_dishes[1] = {bl: []};
        }

        let dishes = [];
        for (let parent of sets[i]) {
          if (typeof iterators[parent] == 'undefined') {
            iterators[parent] = 0;
          }

          dishes.push(dishes_in_parents[parent][iterators[parent]]);
          iterators[parent]++;
        }

        groupped_dishes[1].bl.push({
          set: sets[i],
          dishes: dishes.reverse(),
          key: mask_key,
          price: price
        });
      }

      return groupped_dishes;
    }
  },
  mounted() {
    let self = this;
    if ([1, '1'].indexOf(menu.restaurant.id) > - 1) {
      // @note: для Ибис пока отключаем
    } else {
      self.without_payment = menu.restaurant.without_payment;
      self.show_order_button = true;
      if (self.without_payment) {
        self.order_button_text = 'Заказать'
      }
    }
  },
  methods: {
    addToCart(dish_id) {
      this.$store.commit('addToCart', {id: dish_id, price: menu.dishes[dish_id].price});
    },
    removeFromCart(dish_id) {
      this.$store.commit('removeFromCart', {id: dish_id, price: menu.dishes[dish_id].price});
    },
    preSendOrder() {
      if (this.without_payment) {
        this.sendOrder({});
      } else {
        jewelryModals.show('.modal.modal--pay-2');
      }
    },
    sendOrder(customer) {
      if (this.is_sent > 1) {
        // return;
      }
      App.channels.orders.send({
        action: "create",
        table: this.$store.getters.getTable,
        data: this.groupped_dishes,
        customer: customer
      });
      this.is_sent = 2;
    },
    addToMyOrders: function (data) {
      let tmpArr = {}, keys = [data.order_code], payment_status = data.order_payment_status, key = 'other_orders';

      if (payment_status === 'waiting_for_capture') {
        key = 'pending_orders';
      } else if (payment_status === 'succeeded' || (this.without_payment && [null, 'new'].indexOf(payment_status) > -1)) {
        key = 'my_orders';
      }

      for (let k in this[key]) {
        tmpArr[k] = this[key][k];
        keys.push(k);
      }
      tmpArr[data.order_code] = data.order_view;

      keys = keys.sort().reverse();

      this[key] = {};
      for (let k of keys) {
        this[key][k] = tmpArr[k];
      }

      this['show_' + key] += 1;

      // отобразим сообщение по статусу заказа, если надо
      let o = new URL(location.href).searchParams.get('o'); // номер заказа
      if (o !== null && o === data.order_code) {
        App.cart.$refs.dishes.selected.tab = 2;

        coreFunctions.setHistoryState(document.title, location.href.replace(/\?.+/, ''));
        // console.log(data.order_payment_status);

        if (data.order_payment_status === 'succeeded' || (this.without_payment && [null, 'new'].indexOf(data.order_payment_status) > -1)) {
          let $m = $('.modal--thx' + (this.without_payment ? '--without_payment' : ''));
          $m.addClass('modal--show');

          $m.find('.modal__close').on('click', function (e) {
            if (typeof Cookies.get('rate') === 'undefined') {
              setTimeout(function () {
                $('.modal--rateyo').addClass('modal--show');
              }, 200);
            }
          });

        } else if (data.order_payment_status === 'canceled') {
          $('.modal--cancelled_payment').addClass('modal--show');
        }
      }

    }
  }
}
</script>

<style scoped>
</style>
