<template>
  <div class="card">
    <div :data-dish-id="dish_id"
         :class="[menu.dishes[dish_id].is_hit ? 'card__image card__image--hit' : 'card__image']">
      <img :data-name="menu.dishes[dish_id].name"
           :data-srcsmall="menu.dishes[dish_id].image_small != null ? menu.dishes[dish_id].image_small : '/images/nophoto_small.png'"
           :data-srcbig="menu.dishes[dish_id].image != null ? menu.dishes[dish_id].image : '/images/nophoto.png'"
           @load.once="loadImage"
           src="/images/nophoto_small.png"/>
    </div>
    <div class="card__body">
      <div class="card__content">
        <div class="card__title">
          {{ menu.dishes[dish_id].name | truncate(40, '...')}}
        </div>
        <div class="card__subtitle" v-if="menu.dishes[dish_id].desc">
          {{menu.dishes[dish_id].desc | truncate(40, '...')}}
        </div>
      </div>

      <div class="card__footer">
        <div class="card__price">
          <div v-if="menu.dishes[dish_id].price">
            ₽ {{ menu.dishes[dish_id].price }}
          </div>
        </div>

        <div class="card__nutritional" v-if="false">
          <div v-if="menu.dishes[dish_id].weight">
            {{ menu.dishes[dish_id].weight }} гр
          </div>
        </div>

        <div class="card__nutritional" v-if="false">
          <div v-if="menu.dishes[dish_id].energy_value">
            {{ menu.dishes[dish_id].energy_value }} ккал
          </div>
        </div>
      </div>
    </div>
    <div class="card__counter">
      <div class="counter">
        <button class="counter__minus" @click.prevent="removeFromCart(dish_id)"></button>
        <div class="counter__number counter__number--nozero">{{ count || menu.dishes[dish_id].count_in_basket }}
        </div>
        <button class="counter__plus" @click.prevent="addToCart(dish_id)"></button>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    data: function () {
      return {
        menu: menu
      }
    },
    props: {
      dish_id: 0,
      count: 0
    },
    filters: {
      truncate: function (text, length, suffix) {
        if (text.length > length) {
          return text.substring(0, length) + suffix;
        } else {
          return text;
        }
      },
    },
    methods: {
      loadImage(e) {
        let $dstImg = $(e.target), srcsmall = $dstImg.data('srcsmall'), srcbig = $dstImg.data('srcbig');
        $('<img />').on('load', function () {
          $dstImg.attr('src', srcsmall);
          $('<img />').on('load', function () { $dstImg.attr('src', srcbig); }).attr('src', srcbig);
        }).attr('src', srcsmall);
      },
      addToCart(dish_id) {
        this.$store.commit('addToCart', {id: dish_id, price: menu.dishes[dish_id].price});
      },
      removeFromCart(dish_id) {
        this.$store.commit('removeFromCart', {id: dish_id, price: menu.dishes[dish_id].price});
      }
    }
  }
</script>

<style scoped>
</style>
