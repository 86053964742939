<template>
  <div id="small_cart" class="index__price">
    <div class="price" v-if="totalPrice !== '0.00' ">
      Итог: ₽ {{ totalPrice }}
    </div>
  </div>
</template>

<script>
  export default {
    data: function () {
      return {}
    },
    computed: {
      totalPrice() {
        return this.$store.getters.getTotalPrice;
      }
    }
  }
</script>

<style scoped>
</style>
