<template>
  <div id="dishes_menu" class="index">
    <div class="index__header">
      <!-- tab == 1: меню -->
      <div class="menu" v-if="selected.tab === 1">
        <div class="menu__items">
          <a href="#" v-for="category in menu.categories_sorted"
             v-if="category.parent < 1 && category.dishes"
             :class="[selected.category === category.id ? 'menu__item menu__item--active' : 'menu__item']"
             @click.prevent="selected.category === category.id ? selected.category = 0 : selected.category = category.id">
            <div class="menu__icon"
                 :style="'background-image: url(' + category.image + '); background-size: 40px 40px;'"></div>
            <div class="menu__name">
              {{ category.name }}
            </div>
          </a>
        </div>
      </div>

      <!--//tab == 1: меню -->

      <!-- tab == 2: заказ -->
      <div class="header" v-if="selected.tab === 2">
        <div class="header__body">
          <!--
          <a href="#" @click.prevent="selected.tab = 1" class="header__return"></a>
          -->
          <div class="header__title header__title--order">
            <span v-if="without_payment">Мой заказ</span>
            <span v-else>Избранное</span>
          </div>
        </div>
      </div>
      <!--//tab == 2: заказ -->
    </div>

    <div class="index__body index__body--nullify">
      <small_cart ref="small_cart"><!-- Корзина --></small_cart>

      <div class="index__scroll">
        <!-- tab == 1: меню -->

        <ul class="card__content card__content--submenu" v-if="selected.tab === 1 && selected.category === 1">
          <li class="card__title" v-for="bl_price in menu.bl_prices">
            {{ bl_price.name }} = {{ bl_price.price }}
          </li>
        </ul>

        <div class="cards__comment cards__comment--b"
             v-if="selected.tab === 1 && selected.category === 1 && menu.category_comments[1]"
             v-html="menu.category_comments[1]"><!-- --></div>

        <div class="cards cards--pb" v-if="selected.tab === 1">
          <div class="cards__parts">

            <div v-for="category in menu.categories"
                 v-if="(
                   selected.category === 1
                   ? (category.parent === 1 && category.dishes)
                   : (
                      selected.category
                      ? category.id === selected.category
                      : category.parent === 0 && category.id !== 1
                    )
                 )">
              <div class="cards__header">
                {{ category.name }}
              </div>
              <div class="cards__comment" v-if="menu.category_comments[category.id]"
                   v-html="menu.category_comments[category.id]"><!-- --></div>
              <div class="cards__part">
                <dish_card v-for="dish_id in category.dishes"
                           v-bind:dish_id="dish_id"
                           v-bind:key="dish_id"><!-- --></dish_card>
              </div>
            </div>
          </div>
        </div>
        <!--//tab == 1: меню -->

        <!-- tab == 2: заказ -->
        <my_order :style="selected.tab == 2 ? 'display:block' : 'display:none' " ref="my_order"><!-- --></my_order>
        <!--//tab == 2: заказ -->
      </div>

      <waiter_ring><!-- Колокольчик --></waiter_ring>
    </div>

    <div class="index__footer">
      <div class="footer">
        <div class="footer__buttons">
          <a href="#" :class="[selected.tab === 1 ? 'footer__button footer__button--active' : 'footer__button']"
             @click.prevent="selected.tab = 1">
            <div class="footer__text">
              Меню
            </div>
          </a>

          <a href="#" :class="[selected.tab === 2 ? 'footer__button footer__button--active' : 'footer__button']"
             @click.prevent="selected.tab = 2">
            <items_in_cart><!-- Мой заказ --></items_in_cart>
          </a>
        </div>
      </div>
    </div>

    <div class="modal modal--pay-2">
      <div class="modal__wrapper">
        <div class="modal__body">
          <a href="" class="modal__close"></a>

          <div class="modal__content">

            <div class="modal__form">
              <div class="form form--mb30">
                <div class="form__str form__str--jcta">
                  <div class="modal__title modal__title--mb20">
                    Для оплаты укажите Ваше имя
                  </div>
                  <div class="form__input form__input--modal">
                    <input type="text" placeholder="Имя" v-model="customer.name">
                  </div>
                  <div class="modal__title">
                    Телефон или Email
                  </div>

                  <div class="form__input form__input--modal">
                    <input type="email" placeholder="Email" v-model="customer.email">
                  </div>
                  <div class="form__input form__input--modal">
                    <masked-input mask="\+\7 (111) 111-11-11" placeholder="Телефон"
                                  @input="customer.phone = arguments[1]"/>
                  </div>

                  <div class="modal__title">
                    При желании Вы можете оставить комментарий для официанта
                  </div>
                  <div class="form__input form__input--modal form__input--comment">
                    <input type="text" placeholder="Комментарий" v-model="customer.comment">
                  </div>
                </div>

                <div class="form__button">
                  <a href="#" @click.prevent="sendOrder" class="button"
                     :class="[validateEmail(customer.email) || validatePhone(customer.phone) ? '' : 'button--disable']">Оплатить</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex';
import items_in_cart from './items_in_cart.vue';
import small_cart from './small_cart.vue';
import waiter_ring from './waiter_ring';
import my_order from './my_order';
import dish_card from './dish_card';
import MaskedInput from 'vue-masked-input'

let debounce = require('lodash.debounce');

Vue.use(TurbolinksAdapter);
Vue.use(Vuex);

export default {
  components: {my_order, items_in_cart, small_cart, waiter_ring, dish_card, MaskedInput},
  data: function () {
    return {
      customer: {
        name: '',
        email: '',
        phone: '',
        comment: ''
      },
      menu: menu,
      selected: {
        category: 1,
        tab: 1 // 1 - Меню, 2 - "Мой заказ"
      },
      without_payment: false
    }
  },

  props: {
    table: ''
  },

  mounted: function () {
    let cart = this.$store.getters.getCart, self = this;

    for (let item of cart) {
      if (typeof menu.dishes[item.id] === 'undefined') {
        for (let c = 0; c < item.count; c++) {
          self.$store.commit('removeFromCart', {id: item.id});
        }
      } else {
        menu.dishes[item.id].count_in_basket = item.count;
      }
    }

    self.$store.commit('setTable', self.table);

    // подпишемся на ранее заказанные
    for (let order_code of self.$store.getters.getMyOrders) {
      App.services.subscribeOrderStatus(order_code);
    }

    // меню
    for (let i = 0, keys = Object.keys(menu.categories), len = keys.length; i < len; i++) {
      if (menu.categories[keys[i]].parent === 0 && menu.categories[keys[i]].dishes) {
        self.selected.category = parseInt(keys[i], 10);
        break;
      }
    }

    // отсортированные категории
    menu.categories_sorted = Object.values(menu.categories).sort(function (a, b) {
      return a.sort - b.sort;
    });

    self.without_payment = menu.restaurant.without_payment;
  },

  methods: {
    addToCart(dish_id) {
      this.$store.commit('addToCart', {id: dish_id, price: menu.dishes[dish_id].price});
    },
    removeFromCart(dish_id) {
      this.$store.commit('removeFromCart', {id: dish_id, price: menu.dishes[dish_id].price});
    },
    validateEmail(value) {
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(value);
    },
    validatePhone(value) {
      return value.replace(/\D/g, '').length === 10
    },
    sendOrder: debounce(function () {
      let self = this;
      if (self.validateEmail(self.customer.email) || self.validatePhone(self.customer.phone)) {
        App.cart.$refs.dishes.$refs.my_order.sendOrder(self.customer);
      }
    }, 400),
  }

}
</script>

<style scoped>
</style>
